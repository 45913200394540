import React, { useEffect, useState } from 'react';
import '../styles/Sidebar.css';
import { Avatar, IconButton } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search'
import { RateReviewOutlined } from '@material-ui/icons';
import SidebarChat from './SidebarChat';
import { selectUser } from '../features/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import db, { auth } from '../firebase';

function Sidebar() {

  const user = useSelector(selectUser)
  const [chats, setChats] = useState([])

  useEffect(() => {
    db.collection('chats').onSnapshot((snapshot) =>
      setChats(
        snapshot.docs.map((doc) => ({
          id: doc.id,
          data: doc.data(),
        }))
      )
    );
  }, [])

  const addChat = () => {
    const chatName = prompt('Please Enter a chat name')
    if (chatName) {
      db.collection('chats').add({
        chatName: chatName
      })
    }
  }

  return (
    <div className="sidebar">
      <div className="sidebar-header">
        <Avatar onClick={() => auth.signOut()} src={user.photo} className="sidebar-avatar" />
        <div className="sidebar-input">
          <SearchIcon />
          <input placeholder="Search" />
        </div>
        <IconButton variant="outlined" className="sidebar-inputButton">
          <RateReviewOutlined onClick={addChat} />
        </IconButton>
      </div>
      <div className="sidebar-chats">
        {chats.map(({ id, data: { chatName } }) => (
          <SidebarChat key={id} id={id} chatName={chatName} />
        ))}

      </div>
    </div>
  )
}

export default Sidebar
