import firebase from 'firebase';

const firebaseConfig = {
  apiKey: "AIzaSyBeEzUrG4qlXTdrrQMS54yKrsT9aQN1oUM",
  authDomain: "imessage-clone-b7f3a.firebaseapp.com",
  databaseURL: "https://imessage-clone-b7f3a.firebaseio.com",
  projectId: "imessage-clone-b7f3a",
  storageBucket: "imessage-clone-b7f3a.appspot.com",
  messagingSenderId: "807552767106",
  appId: "1:807552767106:web:cf4fc6f40ea41eb92c109b",
  measurementId: "G-P4GBR0KW3H"
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
const db = firebaseApp.firestore();
const auth = firebaseApp.auth();
const provider = new firebase.auth.GoogleAuthProvider();

export { auth, provider }
export default db;